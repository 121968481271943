import { getEnv } from "@pathwright/ui/src/components/utils/env"
import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"
import { useEffect, useRef } from "react"
import { useTranslate } from "../lng/withTranslate"

export const { captureException, captureMessage } = Sentry

const SentryError = ({ children, user }) => {
  const { t } = useTranslate()

  // static release name gets swapped out when build is deployed
  // (though ideally would be done when project is built)
  const releaseOption =
    process.env.NODE_ENV === "production"
      ? { release: "pathwright-js@7d012c96" }
      : {}

  const ignoreErrors = [
    // https://stackoverflow.com/a/50387233/6362803
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
    // TODO: actually prevent the code from throwing this Uncaught(in promise) error
    t("auth.sign_in_form.error_didnt_recognize"),
    // // CodelyTV uses a Headway script and often see related errors
    // /Headway/
    // Erorr thrown by some 3rd party https://github.com/getsentry/sentry-javascript/issues/3440
    "Non-Error promise rejection captured"
  ]

  // const denyUrls = [
  //   // other school integrations throwing errors we don't care about
  //   /https:\/\/api\.reftagger\.com\//i,
  //   /https:\/\/js\.intercomcdn\.com\//i,
  //   /https:\/\/munchkin\.marketo\.net\//i,
  //   /https:\/\/s\.adroll\.com\//i,
  //   /https:\/\/cdn\.livechatinc\.com\//i
  // ]

  // Expecting that the allowUrls should remove our need of using denyUrls and possibly beforeSend.
  const allowUrls = [
    // Attempting to only send those errors to Sentry which occur in our JS files.
    /https?:\/\/(prod|staging|testing|dev).pathwrightcdn.com\/static\/js\//i
  ]

  // Optionally filter out events.
  // NOTE: allowUrls only applies if the event has a url to parse, so we must manually
  // filter out those events that do not have a url.
  const beforeSend = (event) => {
    // Ignore all events occurring from file urls.
    if (window.location.pathname.startsWith("file:///")) {
      return null
    }

    // Ignore all events whose final frame source is anonymous.
    try {
      if (
        event.exception.values[0].stacktrace.frames[
          event.exception.values[0].stacktrace.frames.length - 1
        ].filename === "<anonymous>"
      ) {
        return null
      }
    } catch (error) {
      // noop
    }

    return event
  }

  useRef(
    Sentry.init({
      ...releaseOption,
      dsn: "https://f43b2d3cb8aa4924bab5f3987d2f913c@o99.ingest.sentry.io/5438",
      environment: getEnv(),
      integrations: [new Integrations.BrowserTracing()],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.0,
      ignoreErrors,
      // denyUrls,
      allowUrls,
      beforeSend
    })
  )

  // scope errors to current user
  useEffect(() => {
    if (user) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
        ip_address: "{{auto}}"
      })
    } else {
      Sentry.configureScope((scope) => scope.setUser(null))
    }
  }, [user])

  return children
}

SentryError.displayName = "SentryError"

export default SentryError

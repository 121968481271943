import { useSyncer, SyncerContextType } from "../syncer/SyncerContext"
import EditorStateProvider, {
  MergedEditorContextValueType
} from "./EditorStateProvider"
import { ReactNode } from "react"
import BlocksConfigProvider, {
  BlocksConfigContextType
} from "../config/BlocksConfigProvider"
import GraphQLSyncer from "../syncer/GraphQLSyncer"
import EditorUIProvider from "./EditorUIProvider"

const EditorStateWithSyncer = ({
  children
}: {
  children: ReactNode | ((props: MergedEditorContextValueType) => ReactNode)
}) => {
  const syncerState: SyncerContextType = useSyncer()

  const { content, blocksContext, syncer, blockTypes, contentLoading } =
    syncerState

  return (
    <EditorStateProvider
      content={content!}
      blocksContext={blocksContext!}
      syncer={syncer!}
      blockTypes={blockTypes!}
      contentLoading={contentLoading}
    >
      {children}
    </EditorStateProvider>
  )
}

type BlocksEditContainerProps = BlocksConfigContextType & {
  children: ReactNode | ((props: MergedEditorContextValueType) => ReactNode)
  Syncer?: any
}

export const BlocksEditContainer = ({
  children,
  Syncer,
  ...config
}: BlocksEditContainerProps) => {
  const SyncProvider = Syncer || GraphQLSyncer

  const syncerProps: Record<string, any> = {}

  if (!Syncer) {
    syncerProps.graphQLEndpoint = config.graphQLEndpoint
    syncerProps.accountID = config.accountID
  }

  return (
    <BlocksConfigProvider {...config}>
      <SyncProvider {...syncerProps}>
        <EditorStateWithSyncer>{children}</EditorStateWithSyncer>
      </SyncProvider>
    </BlocksConfigProvider>
  )
}

export default BlocksEditContainer
